$maxWidthMobile: 767px;
$minWidthPC: 768px;

.skills {
  .row {
    display: flex;
  
    // Mobile
    @media (max-width: $maxWidthMobile) {
      flex-direction: column;
    }
    // PC
    @media (min-width: $minWidthPC) {
      flex-direction: row;
    }
    .col {
      flex: 1;
      .skills-container {
        max-width: 400px;
        .heading {
          font-size: 25px;
          margin-bottom: 15px;
        }
      }
    }
  }
  .skills-row {
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .skill-item {
      margin: 0 10px;
      svg {
        width: 48px;
        height: 48px;
      }
    }
  }
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  width: 100px;
  top: 100%;
  left: 50%;
  margin-left: -50px; /* Use half of the width (120/2 = 60), to center the tooltip */
}
