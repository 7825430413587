.typing-container {
  display: inline-block;
}

.typing-wrapper {
  border-right: solid 3px;
  white-space: nowrap;
  overflow: hidden;

  // animation: typing 3s steps(29,end) 1s 1 normal both,
  //           blink-cursor 600ms steps(29,end) infinite;
}

@keyframes typing {
  from { width: 0; }
  60% {width: 100%; }
  to { width: 100%; }
}

@keyframes blink-cursor {
  50% { border-right-color: transparent; }
}