@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro');

.parallax {
  height: 100vh;
  // background: url(/assets/xxx.jpeg);  // move to tsx file
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .text {
    color: white;

    .title {
      font-size: 68px;
      letter-spacing: 1px;
      margin-bottom: 10px;
      font-weight: 600;
      // Fade in down effect
      opacity: 0;
      transform: translateY(-15vh);
      animation: fadeInDown 1.5s ease forwards;
    }
    .subtitle {
      font-size: 22px;
      font-family: 'Source Code Pro', monospace;
    }
  }
}

@keyframes fadeInDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
