.contact {
  display: flex;
  justify-content: center;
  .contact-item {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 6px;
    }
    svg {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }
    .contact-item-value {
      color: #666666;
    }
  }
}
