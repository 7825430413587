.fixed-top {
  top: 0;
  position: fixed;
    right: 0;
    left: 0;
}

.navbar {
  background-color: transparent;
  height: 64px;
  padding: 8px 0;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  z-index: 500;

  a {
    text-decoration: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-wrapper {
    height: 100%;
    // 居中
    padding: 0 20px;
    margin: 0 auto;
    max-width: 1140px;
    // 子元素布局
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    // mobile: resolution <= 767px
    @media (max-width: 767px) {
      a.navbar-title {
        font-size: 20px;
      }
      // 显示mobile
      .navbar-mobile {
        z-index: 1;
        -webkit-user-select: none;
        user-select: none;

        input {
          // display: block;
          width: 100%;
          height: 100%;

          position: absolute;

          cursor: pointer;

          opacity: 0; /* hide this */
          z-index: 2; /* and place it over the hamburger */

          -webkit-touch-callout: none;

          /* 
          * Transform all the slices of hamburger
          * into a crossmark.
          */
          &:checked ~ span {
            // 所有
            opacity: 1;
            transform: rotate(-45deg) translate(0, -3px);
            background: #000000;
          }

          /*
          * But let's hide the middle one.
          */
          &:checked ~ span#s2 {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
          }

          /*
          * Ohyeah and the last one should go the other direction
          */
          &:checked ~ span#s3 {
            transform: rotate(45deg) translate(0, 3px);
          }

          &:checked ~ .navbar-menu-mobile {
            transform: none;
          }
        }
        span {
          display: block;
          width: 33px;
          height: 4px;
          margin-bottom: 5px;
          position: relative;
          top: 14px;

          background: #ffffff;
          border-radius: 3px;

          z-index: 1;

          // 动画
          // transform-origin: 4px 0px;

          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
        }
        span#s1 {
          transform-origin: right top;
        }
        span#s3 {
          transform-origin: right bottom;
        }
        // mobile menu
        .navbar-menu-mobile {
          // 大小和位置
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          width: 100%;
          padding-top: 64px;
          // 背景为白色
          background: white;
          // 动画效果
          transform-origin: 100% 0%;
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
          transform: translate(0, -100%); // 从上方滑入
          // transform: translate(100%, 0); // 从右方滑入
          a.navbar-menu-item {
            height: 30px;
            font-size: 18px;
            color: #000000;
            &:hover {
              color: #0b5e81;
            }
          }
        }
      }
      // 隐藏PC的menu
      .navbar-desktop {
        display: none;
      }
    }

    // desktop: resolution >= 768px
    @media (min-width: 768px) {
      a.navbar-title {
        font-size: 20px;
        font-weight: 500;
      }
      // 隐藏mobile
      .navbar-mobile {
        display: none;
      }
      // 显示PC的menu
      .navbar-desktop {
        display: block;
        // pc menu
        .navbar-menu {
          height: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          a.navbar-menu-item {
            font-size: 16px;
            margin: 0 16px;
            &:hover {
              color: rgb(185, 185, 185);
            }
          }
        }
      }
    }
  }
}

.navbar.solid {
  // background: linear-gradient(#0b5e81, #4e7fa2, #84a0ba);
  background-color: #0b5e81;
  box-shadow: 0 4px 18px 2px #84a0ba;
}
