.home > section:not(:first-child) {
  padding-top: 64px;
  margin-top: -64px;
}

.home {
  .content-container {
    max-width: 1140px;
    margin: 0 auto;
  }
}
