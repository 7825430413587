.certificates-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  .certificates-item {
    width: 180px;
    height: 180px;
    // gap between items
    margin: 0 30px;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) { /* Adjust 600px to the breakpoint you need */
  .certificates-container {
    flex-direction: column;
    align-items: center;
    .certificates-item {
      width: 160px;
      height: 160px;
      // gap between items
      margin: 8px 0;
      cursor: pointer;
    }
  }
}
