.education{
  .content {
  & > .event:not(:last-child) {
    margin-bottom: 40px;
  }
  .event {
    width: 100%;
    display: flex;
    justify-content: center;

    .left {
      font-size: 14px;
      @media (min-width: 768px) {
        width: 180px;
        margin-right: 60px;
      }
      .duration {
        margin-top: 5px;
        margin-bottom: 10px;
        span {
          padding: 0 5px;
          color: #0b5e81;
          border-color: #0b5e81;
          border-style: solid;
        }
      }
      .location {
        color: #666666;
      }
    }
    .right {
      width: 500px;
      .school-name {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        position: relative;
        .school-logo {
          width: 48px;
          position: absolute;
          top:0;
          right:0;
        }
      }
      .degree-major {
        color: #666666;
        font-size: 16px;
        margin-bottom: 18px;
      }
      .description {
        color: #363636;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }


  }
}

// Mobile
@media (max-width: 767px) {
  .education {
    .content {
      & > .event:not(:last-child) {
        margin-bottom: 46px;
      }
      .event {
        flex-direction: column;
        .left {
          width: 100%;
          text-align: center;
          margin-bottom: 22px;
        }
        .right {
          width: 100%;
        }
      }
    }
  }
}
