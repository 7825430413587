.content-block {
  width: 100%;
  padding: 50px 10px;
  .title {
    margin: 20px 0 70px;
    font-size: 48px;
    font-weight: 300;
    text-align: center;
    span {
      box-shadow: inset 0px -18px 0px #a8cbe4;
    }
  }
}

// Mobile
@media (max-width: 767px) {
  .content-block {
    padding: 40px 16px;
    .title {
      margin: 20px 0 60px;
      font-size: 40px;
    }
  }
}
